<template>
  <div class="page" v-if="detail">
    <section>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/found' }">发现</el-breadcrumb-item>
        <el-breadcrumb-item>问答</el-breadcrumb-item>
      </el-breadcrumb>
      <span class="title">{{ detail.discover.title }}</span>
      <div class="sectionTime">
        <span class="timeName">{{ detail.discover.nickname }}</span>
        <span>{{ detail.discover.create_time | timeFormat }}</span>
      </div>
    </section>
    <!-- 全部回答 -->
    <div class="box">
      <section>
        <div class="group">
          <!-- 个人信息 -->
          <div class="personage">
            <img src="@image/touxiang.png" />
            <div class="personage-right">
              <div class="one">
                <span class="title">{{ detail.answer.doctor.name }}</span>
                <span class="position">{{
                  detail.answer.doctor.title_name
                }}</span>
                <template v-if="detail.answer.doctor.user_id != userInfo.id">
                  <span
                    class="button nullBtn"
                    v-if="detail.answer.attention"
                    @click.stop="postDiscoverAttention(detail.answer)"
                    >已关注</span
                  >
                  <span
                    class="button"
                    v-else
                    @click.stop="postDiscoverAttention(detail.answer)"
                    >+关注</span
                  >
                </template>
              </div>
              <div class="two">
                <span class="site">{{ detail.answer.doctor.company }}</span>
                <span>{{ detail.answer.doctor.department_name }}</span>
              </div>
            </div>
          </div>
          <span class="subTitle">{{ detail.answer.content }}</span>
          <div class="content-imgtwo" v-if="detail.answer.images">
            <img
              @click.stop="imgView(it)"
              :src="it"
              v-for="(it, i) in JSON.parse(detail.answer.images)"
              :key="i"
              alt=""
            />
          </div>

          <div class="detailsTime">
            <span class="pageviews">{{ detail.answer.browse_num }}浏览</span>
            <span>{{ detail.answer.create_time | timeFormat }}</span>
          </div>
          <!-- 点赞收藏图标区 -->
          <div class="icons">
            <div
              class="favoritesNumber"
              @click="collectionFn(detail.discover.collect_status)"
            >
              <i
                class="iconfont shoucang"
                :class="{ shoucanghover: detail.discover.collect_status }"
              ></i>
              <span>{{ detail.discover.collect_num || 0 }}</span>
            </div>
            <div class="like" @click="articlePraiseFn">
              <i
                class="iconfont zan1"
                :class="{ iconzan2: !detail.discover.praise_status }"
              ></i>
              <span>{{ detail.discover.praise_num || 0 }}</span>
            </div>
            <span class="share">分享：</span>
            <share :config="config"></share>
            <!-- <img class="weixin" src="@image/weixin.svg" />
						<img class="qq" src="@image/qq.svg" />
						<img class="weibo" src="@image/weibo.svg" /> -->
          </div>

          <!-- 评论数 -->
          <div class="numberComments">
            <span class="number">{{ detail.comment.length }}</span>
            <span>条评论</span>
          </div>
          <comment
            width="1090"
            :discover_id="$route.query.did"
            @refresh="getDetail"
            :comments="detail.comment"
            :answer_id="$route.query.id"
          ></comment>
        </div>
      </section>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="imgViewUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import comment from "@components/common/comment";
export default {
  components: { comment },
  data() {
    return {
      detail: "",
      dialogVisible: false,
      imgViewUrl: "",
      islod: true,

      star: false,
      config: {
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 关注
    async postDiscoverAttention(item) {
      // 防止多次发送请求
      if (this.islod) {
        this.islod = false;
        let res = await this.$userApi.postDiscoverAttention({
          attention_id: item.user_id,
        });
        if (res.code == 200) {
          if (item.attention) {
            this.$message({ message: "已取消关注", type: "success" });
          } else {
            this.$message({ message: "关注成功", type: "success" });
          }
          item.attention = !item.attention;
        }
        this.islod = true;
      }
    },
    // 图片预览
    imgView(url) {
      this.dialogVisible = true;
      this.imgViewUrl = url;
    },
    // 获取详情
    async getDetail() {
      this.xin.isLoadding(true);
      let res = await this.$findApi.findAnswerInfo({
        answer_id: this.$route.query.id,
      });
      if (res.code == 200) {
        this.detail = res.data || [];
      }
      this.xin.isLoadding();
    },
    // 收藏
    async collectionFn(type) {
      let data = {
        discover_id: this.$route.query.did,
      };
      if (this.islod) {
        this.islod = false;
        let res = await this.$findApi.findCollection(data);
        this.islod = true;
        if (res.code == 200) {
          if (type) {
            this.detail.discover.collect_num -= 1;
            this.$message({ message: "已取消收藏", type: "success" });
          } else {
            this.detail.discover.collect_num += 1;
            this.$message({ message: "收藏成功", type: "success" });
          }
          this.detail.discover.collect_status =
            !this.detail.discover.collect_status;
        }
      }
    },
    // 文章点赞
    async articlePraiseFn() {
      let data = {
        like_id: this.$route.query.did,
        type: 1,
      };
      if (this.islod) {
        this.islod = false;
        let res = await this.$findApi.findCommentLike(data);
        this.islod = true;
        if (res.code == 200) {
          if (this.detail.discover.praise_status) {
            this.detail.discover.praise_num -= 1;
            this.$message({ message: "已取消点赞", type: "success" });
          } else {
            this.detail.discover.praise_num += 1;
            this.$message({ message: "点赞成功", type: "success" });
          }
          this.detail.discover.praise_status =
            !this.detail.discover.praise_status;
        }
      }
    },

    handleRemove(file) {
      file;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      file;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.content-imgone {
  margin-top: 10px;
  img {
    width: 100%;
    height: 390px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }
}
.content-imgtwo {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  img {
    box-sizing: border-box;
    width: 270px;
    height: 130px;
    object-fit: cover;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}
.page {
  > section {
    display: flex;
    flex-direction: column;
    > .title {
      font-size: 24px;
      color: #333333;
    }
    .content {
      margin-top: 30px;
      font-size: 18px;
      color: #8f8f8f;
      line-height: 1.8;
    }
    > .sectionTime {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #8f8f8f;
      margin-top: 16px;
      margin-bottom: 40px;
      > .timeName {
        margin-right: 10px;
      }
    }
  }
  > .box {
    background: #f2f2f2;
    padding: 38px 0;
    box-sizing: border-box;
    > section {
      display: flex;
      flex-direction: column;
      > .boxTitle {
        font-size: 30px;
        color: #333333;
        background: #fff;
        padding: 30px 0 0 30px;
      }
      > .group {
        background: #ffffff;
        padding: 20px 30px;
        box-sizing: border-box;
        > .personage {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          > img {
            width: 70px;
            height: 70px;
            object-fit: cover;
          }
          > .personage-right {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            width: 100%;
            > .one {
              display: flex;
              flex-direction: row;
              color: #333333;
              font-size: 24px;
              > .position {
                font-size: 13px;
                color: $--themeColor;
                width: 75px;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 26px;
                margin-left: 12px;
              }
              > .button {
                font-size: 12px;
                color: #8f8f8f;
                width: 56px;
                height: 25px;
                color: #ffffff;
                background: $--themeColor;
                border-radius: 5px;
                text-align: center;
                line-height: 24px;
                margin-left: auto;
                cursor: pointer;
              }
              .nullBtn {
                color: #8f8f8f !important;
                background: #ffffff !important;
                border: 1px solid #d9d9d9 !important;
              }
            }
            > .two {
              display: flex;
              flex-direction: row;
              color: #8f8f8f;
              font-size: 16px;
              margin-top: 9px;
              > .site {
                margin-right: 20px;
              }
            }
          }
        }
        > .subTitle {
          font-size: 16px;
          color: #333333;
          line-height: 1.8;
        }
        > .ultra {
          width: 1140px;
          height: 556px;
          object-fit: cover;
          margin: 20px 0;
        }
        > .seeDetails {
          display: flex;
          flex-direction: row;
          font-size: 16px;
          color: #333333;
          line-height: 1.8;
          position: relative;
          .model_content {
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            .model_text {
              font-size: 16px;
              color: #657180;
              text-align: justify;
              line-height: 1.8;
            }
            .model_detail {
              width: 5em;
              position: absolute;
              right: 0px;
              bottom: 0;
              background: #fff;
              font-size: 16px;
              color: #657180;
            }
            .more_detail {
              position: absolute;
              right: 0;
              color: $--themeColor;
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
        > .detailsTime {
          display: flex;
          flex-direction: row;
          color: #8f8f8f;
          font-size: 16px;
          margin-top: 20px;
          > .pageviews {
            margin-right: 20px;
          }
        }
        > .icons {
          display: flex;
          align-items: center;
          color: #8f8f8f;
          margin-top: 33px;
          font-size: 24px;
          > .favoritesNumber {
            display: flex;
            align-items: center;
            margin-right: 40px;
            cursor: pointer;
            i {
              margin-right: 2px;
            }
            .shoucanghover {
              color: #e60012;
            }
          }
          > .like {
            display: flex;
            align-items: center;
            cursor: pointer;
            i {
              margin-right: 3px;
            }
            .zan1 {
              color: #e60012;
            }
            .iconzan2 {
              color: #8f8f8f;
            }
          }
          img {
            cursor: pointer;
          }
          > .share {
            font-size: 16px;
            margin-left: auto;
          }
          > .qq {
            margin: 0 20px;
          }
        }
        > .numberComments {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #333333;
          font-size: 24px;
          margin-top: 30px;
          padding-bottom: 16px;
          box-sizing: border-box;
          border-bottom: 1px solid #f2f2f2;
          > .number {
            font-size: 30px;
            color: #e60012;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
